@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');



.loading-spinner {
          margin-left: 10px;
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          animation: spin 2s linear infinite;
          display: inline-block;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
.custom-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}

.custom-dropdown-button {
/*  background-color: #fff;*/
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.flag-icon {
  width: 30px;
  height: 30px;
  margin: 0;
}

.custom-dropdown-content {
  display: none;
  position: absolute;
/*  background-color: #f9f9f9; */
  min-width: 20px; /* Adjust the width if needed */
  /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
  z-index: 1;
  bottom: 100%; /* Position above the button */
  padding: 0;
}

.custom-dropdown:hover .custom-dropdown-content {
  display: block;
}

.custom-dropdown-item {
  padding: 0;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.custom-dropdown-item_internal {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.custom-dropdown-item:hover { 
  /* background-color: #f1f1f1; */
}


.language-dropdown {
  padding: 5px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}
* {
    margin: 0;
    border: none;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
}
body{
    height: 100vh;
    overflow-y: hidden;
}

body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Disable all scrolling */
}

/* hide 1st msg field */
.bot_{
    display: none;
}



/* Popup.css */


.popup-text {
  white-space: pre-wrap; /* Ensure that new lines are displayed */
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is on top of everything */
}

.popup-content {
/*  background: #fff;*/
  padding: 20px;
  border-radius: 10px;
  max-width: 100vw;
  max-height: 90vh;
  overflow-y: auto; /* In case content overflows */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-content h2 {
  margin-bottom: 20px;
}

.popup-content p {
  margin-bottom: 20px;
  font-size: 1rem; /* Adjust font size for readability */
}

.popup-content button {
  padding: 10px 20px;
  font-size: 1rem;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background: #0056b3;
}


.chat-div {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    bottom: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 450px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));
    z-index: 500;
}

.chat-box {
    width: 100%;
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scroll-behavior: smooth;
}



@media screen and (max-width: 1100px) {
    .chat-div {
        width: 400px;
    }
}

.chat-box::-webkit-scrollbar {
    width: 7px;
}

.chat-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.chat-box::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* iframe settings */
@media (max-width: 600px) {
  .chat-box {
    opacity: 0;

  }
  .chat-div{
    background: none;
  }

  .loading{
    position: relative;
  }

  .msg-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    border-radius: 25px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50, 49, 49, 0.678);
}


}
.msg-box {
    border: 1px solid rgba(255, 255, 255, 0.7);
    height: 50px;
    display: flex;
    border-radius: 25px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50, 49, 49, 0.678);
}

#mic {
    height: 40px;
    width: 40px;
    margin-left: 10px;
    border-radius: 20px;
    transition: all ease 0.3s;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    pointer-events: none;
    user-select: none;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        background-color: #ff3380; /*  */
    }
    50% {
        transform: scale(1.2);
        background-color: #ff65d4; /*  */
    }
    100% {
        transform: scale(1);
        background-color: #ff3380; /*  */
    }
}


#mic_active {
    height: 35px;
    width: 35px;
    margin-left: 10px;
    border-radius: 20px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    background-color: #00FF00; /* initial green color */
    animation: pulse 1.6s infinite ease-in-out;
}



.msg-box input {
    width: calc(75%);
    background-color: transparent;
    font-size: 17px;
    color: rgb(236, 234, 234);
}

.msg-box input::placeholder {
    color: rgb(209, 209, 209);
}

#send {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 20px;

}

#send:hover {
    background-color: #35a4f3;
}

.msgbtn {
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.msgbtn img {
    width:20px;
    height: 20px;
}

.chat-box p {
    width: fit-content;
    font-size: 16px;
    padding: 10px 15px;
    color: white;
    border-radius: 10px;
    background-color: rgb(31, 31, 31);
    margin-block: 5px;
    max-width: 350px;
    margin-left: 5px;
}

.chat-box p.me {
    margin-left: auto;
    margin-right: 10px;
    background-color: #35a4f3;
}

.chat-box p .time {
    font-size: 10px;
    color: rgb(143, 143, 143);
    text-align: end;
}

.Toastify__progress-bar {
    height: 4px;
}

@media screen and (max-width: 750px) {
    .chat-div {
        width: 350px;
    }
}

@media screen and (max-width: 500px) {

    .chat-div {
        width: 100%;
        height: 350px;
        bottom: 0;
/*        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));*/
    }

    .chat-box {
        height: calc(100vh - 70px);
        height: calc(var(--vh, 1vh) * 100 - 70px);
    }

    .msg-box {
        margin-bottom: 10px;
   /*     margin-left: 7px; */
       /* margin-right: 7px; */
    }

    .chat-box p {
        font-size: 15px;
    }

    .msg-box input {
        font-size: 15px;
    }
}

.about{
    position: absolute;
    left: 15px;
    top: 40px;
    display: flex;
    z-index: 3;
    padding: 7px;
    background-color: #222;
    border-radius: 5px;
    cursor: pointer;
}
.about img{
    width: 22px;
    height: 22px;
}
.modal{
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 90%;
    max-width: 400px;
    padding: 10px;
    text-align: center;
    background: rgba(29, 28, 28, 0.7);
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    transition: all 1s ease-out;
    animation: ease-out 1s;
}
.modal h1{
    margin-top: 20px;
}
.modal a{
    color: #35a4f3;
    text-decoration: none;
}